import { IUserStore } from 'interfaces/store/IUserStore';
import create, { StateCreator } from 'zustand';
import api from 'services/api';
import { IStore } from 'interfaces/store/IStore';
import { persist } from "zustand/middleware";
import { STORE_KEY } from 'constants/APP_CONSTANTS';
import { removeUserFromMultipleLocalStorageKeys } from 'utils/notFoundUserLocalStorageData';
import { ATTEPTS_USERS_FROMDB, FIRST_ACCESS_REQUERED_LOCAL_STORAGE_KEY } from 'constants/LOGIN_ATTEMPTS';

const USER_DATA = {
    user: {
      id: "",
      name: "",
      hasATempPassword: false,
    },
    bearerToken: {
      token: "",
      refreshToken: "",
      expires: "",
    },
  } as IUserStore;

const store: StateCreator<IStore> = (set, get) => ({
    ...USER_DATA,
    getApi: () => {
        const token = get().bearerToken.token;
        api.defaults.headers.common.authorization = token ? `Bearer ${token}` : "";
        return api;
      },
    signin: async (params) => {
        const api = get().getApi();
        const { data } = await api.post<IUserStore>("/auth/login", params);
        api.defaults.headers.common.authorization = `Bearer ${data.bearerToken.token}`;
        set(() => ({
          ...get(),
          ...data,
        }));
        const document = params.username
        removeUserFromMultipleLocalStorageKeys([FIRST_ACCESS_REQUERED_LOCAL_STORAGE_KEY, ATTEPTS_USERS_FROMDB], document)
        if(data?.user?.role === "client") await api.get("/clients/sync");
    },
    signout: async () => {
        const api = get().getApi();
        api.defaults.headers.common.authorization = "";
          set(() => ({
            ...get(),
            ...USER_DATA,
          }));
      },
      renewToken: async () => {
        try {
          const api = get().getApi();
          const { data } = await api.post("/auth/refresh", {
            refreshToken: get().bearerToken.refreshToken,
          });
          api.defaults.headers.common.authorization = `Bearer ${data.bearerToken.token}`;
          set(() => ({
            ...get(),
            ...data,
          }));
        } catch (err) {

        }
      },
      updatePassword: async (params) => {
        const api = get().getApi();
        try {
          await api.post("/auth/new_password", params);
        } catch(err) {
          //
        } finally {
          api.defaults.headers.common.authorization = "";
          set(() => ({
            ...get(),
            ...USER_DATA,
          }));
        }
      },
  });

const useStore = create(persist(store, {name: STORE_KEY}));

export default useStore;